import React, { useContext } from 'react';
// import { useLogin } from './LoginContext';
import api from '../api/greenCatalog';

const ApiContext = React.createContext<any>({});

export function useApi() {
    return useContext(ApiContext);
}

const ApiProvider = ({ children }: any) => {
    // const { state } = useLogin();
    // const headConfig = {
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: state?.token
    //     }
    // };
    // api.defaults.headers.common['Authorization'] = state?.token;

    const fetchReferenceProducts = async (viewerId: string) => {
        const whereObject = {
            viewer: viewerId
        };
        const whereQuery = encodeURIComponent(JSON.stringify(whereObject));
        try {
            const response = await api.get(`/viewer_add_ons/reference_products?where=${whereQuery}`);
            return response.data;
        } catch (err: any) {
            if (err.response.status === 404) {
                const errMsg = "This viewer does not exist on the server";
                throw errMsg;
            } else {
                throw err.response ? err.response : "Can't connect to server :(";
            }
        }
    };

    const fetchViewerGallery = async (viewerId: string) => {
        const whereObject = {
            viewer: viewerId
        };
        const whereQuery = encodeURIComponent(JSON.stringify(whereObject));
        try {
            const response = await api.get(`/viewer_add_ons/galleries?where=${whereQuery}`);
            return response.data;
        } catch (err: any) {
            if (err.response.status === 404) {
                const errMsg = "This viewer does not exist on the server";
                throw errMsg;
            } else {
                throw err.response ? err.response : "Can't connect to server :(";
            }
        }
    };

    const fetchViewer = async (viewerId: string) => {
        try {
            const response = await api.get(`/viewers/${viewerId}`);
            return response.data;
        } catch (err: any) {
            if (err.response.status === 404) {
                const errMsg = "This viewer does not exist on the server";
                throw errMsg;
            } else {
                throw err.response ? err.response : "Can't connect to server :(";
            }
        }
    };

    const fetchProduct = async (productId: string) => {
        try {
            const response = await api.get(`/application_products/${productId}`);
            return response.data;
        } catch (err: any) {
            if (err.response.status === 404) {
                const errMsg = "This product does not exist on the server";
                throw errMsg;
            } else {
                throw err.response ? err.response : "Can't connect to server :(";
            }
        }
    };

    const fetchProducts = async (
        appId: string,
        pageNumber: number,
        query: string,
        categoryIds: any
    ) => {
        const whereObject = {
            application: appId,
            ...(categoryIds && categoryIds?.length > 0 && {
                categories: { $in: categoryIds }
            }),
            ...(query && {
                $or: [
                    { name: { $regex: query, $options: "i" } },
                    { lookup_id: { $regex: query, $options: "i" } },
                ],
            }),
        };
        const whereQuery = encodeURIComponent(JSON.stringify(whereObject));
        try {
            const res = await api.get(`/application_products?where=${whereQuery}&max_results=20&page=${pageNumber}`);
            return res.data;
        } catch (err: any) {
            throw err?.response?.data?._issues
                ? err.response.data._issues
                : "Couldn't fetch the products :( Please try again later.";
        }
    };

    const fetchApplication = async (appId: string) => {
        try {
            const response = await api.get(`/applications/${appId}`);
            return response.data;
        } catch (err: any) {
            throw err.response ? err.response : "Can't connect to server :(";
        }
    };


    const data = {
        fetchProduct,
        fetchProducts,
        fetchViewer,
        fetchViewerGallery,
        fetchReferenceProducts,
        fetchApplication
    };

    return (
        <ApiContext.Provider value={data}>
            {children}
        </ApiContext.Provider>
    )
}

export default ApiProvider;