import React, { useCallback, useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./SingleProduct.module.css";
import { generateUUID } from "../../../../../utilities";
import { Spinner } from "react-bootstrap";
import ProductsModal from "../../../../ProductsModal";

interface IProps {
  productData: any;
  textureUrl?: string;
  arButtonPosition: string | null;
}

interface MessageEventProps {
  type: string;
  payload?: any;
}

const MessageEventType = Object.freeze({
  INITIALIZED: "INITIALIZED",
  CUSTOM_RULE: "CUSTOM_RULE",
  LOAD_VARIANT: "LOAD_VARIANT",
  POST_MESSAGE_IFRAME: "POST_MESSAGE_IFRAME"
});

const SingleProduct: React.FC<IProps> = ({ productData, textureUrl, arButtonPosition }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [productInitialized, setProductInitialized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [messageData, setMessageData] = useState<MessageEventProps>();

  useEffect(() => {
    setLoading(true);
    setProductInitialized(false);
  }, [productData]);

  const createChangeMaterialRuleJSON = useCallback((textureUrl: string, materialName: string = "reference_material", doPrefixMatch: boolean = true) => {
    return {
      id: generateUUID(),
      type: "Manual",
      actions: [
        {
          parameters: {
            do_prefix_match: doPrefixMatch,
            material: {
              name: materialName,
              texture: textureUrl,
              center: {
                x: 0.5,
                y: 0.5,
              },
            },
          },
          type: "ChangeMaterial",
        },
      ],
    };
  }, []);

  const passDataToIFrame = useCallback(() => {
    if (textureUrl && iframeRef?.current?.contentWindow) {
      const ruleJSON = createChangeMaterialRuleJSON(textureUrl);
      iframeRef.current.contentWindow.postMessage(
        {
          type: MessageEventType.CUSTOM_RULE,
          ruleJSON
        },
        "*"
      );
    }
  }, [textureUrl, createChangeMaterialRuleJSON]);

  const applyTextureToMaterial = useCallback((textureSrc: string) => {
    if (textureSrc && iframeRef?.current?.contentWindow) {
      let materialName = messageData?.payload?.materialName;
      const ruleJSON = createChangeMaterialRuleJSON(textureSrc, materialName, false);
      iframeRef.current.contentWindow.postMessage(
        {
          type: MessageEventType.CUSTOM_RULE,
          ruleJSON
        },
        "*"
      );
      setShowModal(false);
      setMessageData(undefined);
    }
  }, [messageData, createChangeMaterialRuleJSON]);

  useEffect(() => {
    if (textureUrl && productInitialized) {
      passDataToIFrame();
    }
  }, [textureUrl, productInitialized, passDataToIFrame]);

  useEffect(() => {
    const handler = (e: any) => {
      if (e?.data?.type) {
        switch (e?.data?.type) {
          case MessageEventType.INITIALIZED:
            setProductInitialized(true);
            break;
          case MessageEventType.POST_MESSAGE_IFRAME:
            setMessageData(e.data);
            setShowModal(true);
            break;
        }
      }
    }

    if (window) {
      window.addEventListener("message", handler);
    }

    return () => {
      if (window)
        window.removeEventListener("message", handler);
    }
  }, []);

  return (
    <Row style={{ height: "100%", position: "relative" }}>
      {loading &&
        <div className={styles.loaderContainer}>
          <Spinner animation="border" />
        </div>
      }
      {productData && (
        <Col style={{ width: "100%", height: "100%" }}>
          <iframe
            ref={iframeRef}
            allow="xr-spatial-tracking;fullscreen;"
            onLoad={() => setLoading(false)}
            className="h-full w-full"
            style={{ width: "100%", height: "100%" }}
            src={
              `https://viewer.helloar.io?ar_button_position=${arButtonPosition}&id=${productData?._id}`
            }
            title="viewer"
          ></iframe>
        </Col>
      )}
      <ProductsModal
        showModal={showModal}
        hide={() => setShowModal(false)}
        onProductClick={(imageSrc) => applyTextureToMaterial(imageSrc)}
      />
    </Row>
  );
};

export default SingleProduct;
